.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-element {
    display: inline-block;
    width: 65px;
    height: 65px;
    background-size: contain;
    &.-white {
      background: url('../../assets/loader/loader-etam.svg') no-repeat center;
    }
    &.-black {
      background: url('../../assets/loader/loader-etam-black.svg') no-repeat center;
    }
  }
}

.btn {
  .loader-element {
    width: 40px;
    height: 40px;
  }
}