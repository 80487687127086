.btn {
  border: none;
  font-size: rem-calc(13);
  font-weight: 400;
  height: 45px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
  transform: translateZ(0);
  transition: all .3s ease;
  backface-visibility: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $space-m;
  
  &-primary {
    color: $white;
    background: $black;

    &:hover,
    &.-hover {
      background: $greyHover;
      text-decoration: none;
    }

    &:not(.-selected):not(.-disabled) {
      &:active {
        transform: scale(.9);
      }
    }

    &:disabled {
      background: $greyDark;
    }
  }

  &-secondary {
    color: $black;
    background: $white;
    border: 1px solid $black;

    &:hover,
    &.-hover {
      background: $black;
      color: $white;
      text-decoration: none;
    }

    &:active {
      transform: scale(.9);
    }

    &:disabled {
      background: none;
      color: $greyDisable;
      border-color: $greyDisable;
    }
  }

  &.-disabled {
    background-color: $greyDisable;
  }
}
