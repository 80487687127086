/**********************************************************
********************** zIndex Class ***********************
**********************************************************/


.depth-neg {
    z-index: -1;
}
.depth-1{
    z-index: 1;
}
.depth-2{
    z-index: 2;
}
.depth-3{
    z-index: 3;
}
.depth-4{
    z-index: 4;
}
.depth-5{
    z-index: 5;
}
.depth-6{
    z-index: 6;
}
.depth-7{
    z-index: 7;
}
.depth-8{
    z-index: 8;
}
.depth-9{
    z-index: 9;
}
.depth-10{
    z-index: 10;
}