@import '../../style/helpers/helpers';

.charts {
  &__pies {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(auto, 400px));
    justify-content: space-between;
  }

  &__pie {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px $greyLight;
  }

  &__salesRevenue {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    background-color: $whiteDark;
    height: 150px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px $greyLight;
  }
}

.datas {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 400px));
  gap: 10px;
  justify-content: center;

  .bloc {
    background-color: $whiteDark;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 5px 5px 5px $greyLight;
  }

  .title {
    color: #6f6f6f;
  }
}