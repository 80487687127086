@import '../../style/helpers/helpers';

.header {
  &__profil {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blackLight;
    color: $whiteDark;
    cursor: pointer;
    font-size: rem-calc(16);
  }
}