@import '../../style/helpers/helpers';

.login {
  &-page {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    position: relative;
    &.page {
      padding-left: 0;
    }
  }
  &__leftPart {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    background-color: $white;
    padding: $space-l;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include breakpoint(s) {
      width: 350px;
    }
    @include breakpoint(m) {
      width: $loginWidth;
      position: relative;
      transform: inherit;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 150px;
      justify-content: center;
    }
  }
  &__rightPart {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/bkg/login3.png');
    @include breakpoint(m) {
      display: block;
      width: calc(100% - $loginWidth);
      height: 100%;
    }
  }
  &__error {
    margin-bottom: $space-s;
    text-align: center;
  }
}