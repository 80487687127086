body {
  background-color: $background;
  font-family: $primaryFont;
  font-size: rem-calc(12);
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

#root {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; 
}

.page {
  width: 100%;
  padding-left: $sidebarHeight;
} 

a {
  outline: medium none;
  overflow: hidden;
  text-decoration: none;
}

fieldset {
  min-width: min-content;
  border: 0 none;
  margin: 0;
  padding: 0;
}

.hideDesktop {
  @include breakpoint(m) {
    display: none !important;
  }
}

.hideMobile {
  @include breakpoint(m, max-width) {
    display: none !important;
  }
}

@include breakpoint(m, max-width) {
  .showDesktop {
    display: none !important;
  }
}

@include breakpoint(m) {
  .showMobile {
    display: none !important;
  }
}

.clearboth {
  width: 100%;
  clear: both;
  display: block;
}

.lasttimecached {
  display: none;
}

.react-parallax {
  .react-parallax-content {
    position: relative;
    height: 100%;
    width: 100%;
  }
}

.appContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $space-xxl $space-xxl $space-l $space-xxl;
  &__container {
    padding: $space-xl 0;
    border-radius: 40px;
  }
}

.maxContent {
  width: 100%;
  max-width: 90%;
  margin: auto;
  @include breakpoint(l) {
    max-width: $maxContent;
  }
}

.error {
  color: $error;
}