// Primary Colors

$black: #000000 !default;
$white: #ffffff !default;

// Secondary Colors
$background: #F0F0FD !default;
$mistyPink: #f9e7e1 !default;
$linen: #FBF5E8 !default;
$promo: #FF4538 !default;
$pinkStore: #F18FA2 !default;
$loyalty: #ED8D35 !default;
$negativeRed: #AB2929 !default;
$positiveGreen: #67CE67 !default;

// Neutral Colors
$greyHover: #3A3A3A!default;
$greyDark: #A19A9E!default;
$greyDisable: #C5BEC2!default;
$greyLight: #E7E3E5!default;
$greySideBar: #ededf6!default;
$blackLight: #212529!default;
$whiteDark: #F8F9FA!default;

$error: #d60000 !default;
$warning: #ffa903 !default;
$valid: #019a10 !default;

/**
  Images
*/
$arrow: '../img/multiArrow.png' !default;

%clearfix {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

/* Transitions types */
$trEase: ease-in-out !default;
$trBounce: cubic-bezier(0.5, 1.6, 0.45, 0.7) !default;
$trLinear: linear !default;

$trPrimary: $trEase !default;
$trSecondary: $trBounce !default;
$trTertiary: $trLinear !default;

$maxContent: 1440px;
$minContent: 1020px;

$sidebarHeight: 90px;

$loginWidth: 600px;