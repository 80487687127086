.fadeElement {
  transition: all 0.3s $trPrimary;
  height: auto;
  transform: scaleY(1);
  transform-origin: top;
  display: block;

  &.-float {
    float: left;
  }

  &.-opacity {
    transition-property: opacity;
  }

  &.-delay {
    transition-delay: .3s;
  }

  &.-hidden {
    overflow: hidden;
    visibility: hidden;
    margin: 0;
    padding: 0;
    max-height: 0;
    height: 0;
    opacity: 0;
    transform: scaleY(0);
  }
}