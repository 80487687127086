@import '../../style/helpers/helpers';

.sidebar {
  &__container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: $sidebarHeight;
    height: 100%;
    background-color: $blackLight;
  }
  &__linkContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }
  &__link {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 50px;
    &.-logo {
      svg {
        color: $whiteDark;
        path {
          fill: $whiteDark;
        }
      }
    }
    &.-etam {
      svg {
        height: 20px;
      }
    }
    &.-undiz {
      svg {
        height: 15px;
      }
    }
    &.-maison123 {
      svg {
        height: 18px;
      }
    }
    &.-logout {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      svg {
        transition: color 0.5s $trPrimary;  
      }
      &:hover {
        svg {
          color: $loyalty;
        }
      }
      .loader-element {
        width: 30px;
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &.active {
        filter: invert(1);
        background: #212529;
      }
      &:after {
        content: "";
        width: 3px;
        height: 0%;
        position: absolute;
        top: 0;
        left: 0px;
        background-color: $loyalty;
        transition: height 0.3s ease-in-out;
        border-radius: 50px;
      }
      &:hover {
        &:after {
          height: 100%;
        }
      } 
      &.active {
        &:after {
          height: 100%;
        }
      }
    }
    svg {
      width: 100%;
      height: 25px;
      color: $whiteDark;
    }
  }
}