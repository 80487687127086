@import '../../style/helpers/helpers';

.filterSidebar {
  &__container {
    position: relative;
    margin: auto;
    z-index: 10;
  }

  &__content {
    height: 100%;
  }
}

.filterDate {
  &__container {
    margin-left: $space-xl;
  }
  &__item {
    background-color: transparent;
    padding: $space-s $space-m;
    font-size: rem-calc(14);
    cursor: pointer;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    &:hover {
      background-color: $loyalty;
      color: $white;
    }
    &.-active {
      background-color: $loyalty;
      color: $white;
    }
  }
}