@import '../../style/helpers/helpers';

.orderDetails {
  &__close {
    width: 25px;
    height: 25px;
    position: absolute;
    top: $space-l;
    right: $space-l;
  }
}